export default {
	0: 'Главный приз',
	1: 'второй приз',
	2: 'Третий приз',
	3: 'Четвертый приз',
	4: 'Пятый приз',
	5: 'Шестой приз',
	6: 'Седьмой приз',
	7: 'Восьмой приз',
	8: 'Девятый приз',
	9: 'Десятый приз',
};
