import { useSearchParams } from 'react-router-dom';
import { Html5QrcodeScanner } from 'html5-qrcode';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import React from 'react';

import uploadPassportValidator from './../../validators/uploadPassport';
import registerValidator from './../../validators/register';

import prizes from '../../common/prizes';

import Game from '../../components/Game/Game';

export default ({
	promotion,
	successRegistration,
	successLogin,
	participantId,
	participantToken,
	participant,
	passportUploaded,
	passportScanUploaded,
	loginParticipantAction,
	registerParticipantAction,
	getParticipantDataAction,
	registerParticipantBillAction,
	registerParticipantQuestionAction,
	billUploaded,
	questionUploaded,
	registerParticipantPassportAction,
	registerParticipantScanPassportAction,
	logoutAccountAction,
	clearNotifications,
	clearParticipant,
}) => {
	const [ShowQRScaner, SetShowQRScaner] = React.useState(false);
	const [QrScanner, SetQrScanner] = React.useState();
	const [ShowBillScaner, SetShowBillScaner] = React.useState(false);
	const [BillScanner, SetBillScanner] = React.useState();
	const [Prizes, SetPrizes] = React.useState([]);
	const [PromotionGames, SetPromotionGames] = React.useState([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const FormikRef = React.useRef();
	const FormikQuestion = React.useRef();

	const [registerView, SetRegisterView] = React.useState(false);
	const [loginView, SetLoginView] = React.useState(false);
	const [participantView, SetParticipantView] = React.useState(false);

	const [passportView, SetPassportView] = React.useState(false);
	const [passportDataView, SetPassportDataView] = React.useState(false);
	const [passportScanView, SetPassportScanView] = React.useState(false);

	const [registerQuestionView, SetRegisterQuestionView] = React.useState(false);
	const [registerBillView, SetRegisterBillView] = React.useState(false);
	const [quizView, SetQuizView] = React.useState(false);
	const [balance, setBalance] = React.useState(0);

	const [Codes, SetCodes] = React.useState([]);
	const [Games, SetGames] = React.useState([]);
	const [ParticipantPrizes, SetParticipantPrizes] = React.useState([]);
	const [Questions, SetQuestions] = React.useState([]);

	const [ErrorUnauthorized, SetErrorUnauthorized] = React.useState(searchParams.get('error'));


	React.useEffect(() => {
		if (searchParams.has("error") && searchParams.get("error") === 'Unauthorized') {
			// show the message to user
			showErrorMessage('Токен истек. Для продолжения необходимо авторизироваться')

			// logout from current user
			clearParticipant()
			logoutAccount()
		}

	}, [ErrorUnauthorized]);

	const showErrorMessage = (message) => {
		toast(message, {
			autoClose: 5000,
			closeOnClick: true,
			draggable: true,
			hideProgressBar: false,
			pauseOnHover: true,
			position: 'top-right',
			type: 'error',
			theme: 'colored',
		});
	};

	const showSuccessMessage = (message) => {
		toast(message, {
			autoClose: 5000,
			closeOnClick: true,
			draggable: true,
			hideProgressBar: false,
			pauseOnHover: true,
			position: 'top-right',
			type: 'success',
			theme: 'colored',
		});
	};

	const parseFullTime = (time)  => {
		time = new Date(time);

		let date = time.getDate();
		let month = time.getMonth() + 1;
		let year = time.getFullYear();

		let hours = time.getHours();
		let minutes = time.getMinutes();

		if (date < 10) date = "0" + date;

		if (month < 10) month = "0" + month;

		if (hours < 10) hours = "0" + hours;

		if (minutes < 10) minutes = "0" + minutes;

		return `${date}/${month}/${year} ${hours}:${minutes}`;
	};

	const startScanQr = () => {
		SetShowQRScaner(true);

		QrScanner.render(handleQrScan);
	};

	const handleQrScan = (result) => {
		if (FormikRef.current && result.indexOf('code=') !== -1) {

			let params = (new URL(result)).searchParams;
			let promo = params.get("promotion");
			let code = params.get("code");

			console.log({
				name: 'handleQrScan',
				result,
				promo,
				code
			})

			SetShowQRScaner(false);
			QrScanner.pause(true);

			// this code is not from current promotion
			if(promo !== promotion.id) {
				showErrorMessage('QR код не принадлежит текущей акции!');
			}
			else {
				FormikRef.current.setFieldValue('code', code);
			}

		}
	};

	const startScanBill = () => {
		SetShowBillScaner(true);

		BillScanner.render(handleBillScan);
	};

	const handleBillScan = (result) => {
		if (FormikRef.current && result.indexOf('&fn=') !== -1) {
			console.log(`handleBillScan: ${result}`)

			SetShowBillScaner(false);
			BillScanner.pause(true);

			FormikRef.current.setFieldValue('qr', result);
		}
	};


	const registerParticipant = (values) => {
		const additionalFields = Object.entries(values)
			.filter((v) => v[0].indexOf('additional_fields_') !== -1)
			.map((v, i) => ({
				name: promotion.personal_data.additional_fields[i].name,
				type: promotion.personal_data.additional_fields[i].type,
				value: v[1],
			}));

		registerParticipantAction({
			promotion: promotion.id,
			name: values.name,
			surname: values.surname,
			lastname: values.lastname,
			phone: values.phone,
			email: values.email,
			birthday: values.birthday,
			address: values.address,
			gender: values.gender,
			additional_fields: additionalFields,
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
		});
	};

	const uploadPassportData = (values) => {
		if(passportDataView)
			uploadPassport(values)
		else if(passportScanView)
			uploadPassportScan(values)
	};


	const loginParticipant = (values) => {
		let success = true
		let message = ''

		if(values == null) {
			success = false
			message = 'Ощибка чтения введенных данных'
		}
		else if(promotion.register_participants.email && !promotion.register_participants.phone && isEmpty(values.email)) {
			success = false
			message = 'Введите ваш Email'
		}
		else if(promotion.register_participants.phone && !promotion.register_participants.email && isEmpty(values.phone)) {
			success = false
			message = 'Введите ваш Номер телефона'
		}
		else if(promotion.register_participants.email && promotion.register_participants.phone && isEmpty(values.login)) {
			success = false
			message = 'Введите ваш ваш Email или Номер телефона'
		}
		else if(isEmpty(values.password)) {
			success = false
			message = 'Введите  ваш Пароль'
		}

		if(success) {
			// call login request
			console.log({name:'loginParticipant',values})

			loginParticipantAction({
				promotion: promotion.id,
				email: values.email,
				phone: values.phone,
				login: values.login,
				password: values.password,
			});
		}
		else {
			// show error message
			showErrorMessage(message);
		}
	}

	const uploadPassport = (values) => {
		let userId = participant ? participant.fk_user ? participant.fk_user.id : null : null

		registerParticipantPassportAction({
			token: participantToken,
			id: userId,
			passport: {
				// string
				seria: values.seria,
				// string
				number: values.number,
				// string
				who: values.who,
				// string
				department: values.department,
				// date
				when: values.when,
			},
		});
	};

	const uploadPassportScan = (values) => {
		let formData = new FormData();

		let userId = participant ? participant.fk_user ? participant.fk_user.id : null : null

		formData.append('scan', values.scan);
		formData.append('id', userId);

		registerParticipantScanPassportAction({ id: userId, token: participantToken, scan: formData });
	};

	const showRegisterView = () => {
		SetLoginView(false);
		SetRegisterView(true);
	};

	const showLoginView = () => {
		SetRegisterView(false);
		SetLoginView(true);
	};

	const showCabinetView = () => {
		SetRegisterView(false);
		SetLoginView(false);

		// get participant data information
		getParticipantData();
	};

	const getParticipantData = () => {
		// for test: clear the participant data
		clearParticipant()

		getParticipantDataAction({
			token: participantToken,
			id: participantId,
			promotion: promotion.id,
		});

	};

	const showParticipantView = () => {
		//console.log(participant)

		// check if need to load passport information
		let showPassportDataView = false
		let showPassportScanView = false

		if(promotion && promotion.personal_data && promotion.personal_data.passport_data) {
			showPassportDataView = !passportDataIsFull(participant.fk_user.passport)
			showPassportScanView = !passportScansIsFull(participant.fk_user.passport)
		}

		if(showPassportDataView) {
			SetParticipantView(false)

			SetPassportScanView(false)
			SetPassportView(true)
			SetPassportDataView(true)
		}
		else if(showPassportScanView) {
			SetParticipantView(false)

			SetPassportDataView(false)
			SetPassportView(true)
			SetPassportScanView(true)
		}
		else {
			SetPassportView(false)

			SetParticipantView(true)
		}
	};

	const logoutAccount = () => {
		console.log('logoutAccount')
		logoutAccountAction()
	};

	const showRegisterBillView = () => {
		SetQuizView(false);
		SetRegisterQuestionView(false);
		SetRegisterBillView(true);
	};

	const showQuizGameView = () => {
		SetRegisterBillView(false);
		SetRegisterQuestionView(false);
		SetQuizView(true);
	};

	const showRegisterQuestionView = () => {
		SetRegisterBillView(false);
		SetQuizView(false);
		SetRegisterQuestionView(true);
	};

	const billStatus = (status) => {
		switch (status) {
			case 0:
				return `Чек некорректен`
			case 1:
				return `Чек успешно обработан`
			case 2:
				return `Данные чека пока не получены`
			case 3:
				return `Превышено кол-во запросов`
			case 4:
				return `Ожидание перед повторным запросом`
			case 5:
				return `Прочее (данные не получены)`
			case 10:
				return `Невозножно получить информацию о чеке`
			case 11:
				return `Ожидает ручной проверки`
			case 12:
				return `Чек не валидный`
			default:
				return `Неизвестная ошибка`
		}
	};

	const clearRegisterBillView = () => {
		SetRegisterBillView(false);

		if(FormikRef.current) {
			FormikRef.current.setFieldValue('code', '');
			FormikRef.current.setFieldValue('qr', '');
		}
	};

	const clearRegisterQuestionView = () => {
		SetRegisterQuestionView(false);

		if(FormikQuestion.current) {
			FormikQuestion.current.setFieldValue('question', '');
		}
	};

	const registerBill = (values) => {
		let success = true
		let message = ''

		if(values == null) {
			success = false
			message = 'Ощибка чтения введенных данных'
		}
		// else if(isEmpty(values.code)) {
		// 	success = false
		// 	message = 'Отсканируйте QR код товара'
		// }
		// else if(!values.file && isEmpty(values.qr)) {
		// 	success = false
		// 	message = 'Отсканируйте QR код чека или Сделайте его фотографию, в котором указан аукционный товар'
		// }

		if(success) {
			// call register bill request
			console.log({name:'registerBill', values})

			let formData = new FormData();
			if(values.file)
				formData.append('file', values.file);

			registerParticipantBillAction({
				token: participantToken,
				promotion: promotion.id,
				participant: participant.id,
				//codes: [values.code],
				code: values.code,
				qr: values.qr,
				photoQr: values.file ? true : false,
				file: formData
			});
		}
		else {
			// show error message
			showErrorMessage(message);
		}
	};

	const registerQuestion = (values) => {
		let success = true
		let message = ''

		if(values == null) {
			success = false
			message = 'Ощибка чтения введенных данных'
		}
		else if(isEmpty(values.question)) {
			success = false
			message = 'Введите ваш вопрос для службы поддержки'
		}

		if(success) {
			// call register bill request
			console.log({name:'registerQuestion', values})

			registerParticipantQuestionAction({
				token: participantToken,
				promotion: promotion.id,
				participant: participant.id,
				question: values.question
			});
		}
		else {
			// show error message
			showErrorMessage(message);
		}
	};

	const getFullName = (user) => {
		if(isEmpty(user))
			return ''

		return `${user.name ? user.name : ''} ${user.surname ? user.surname : ''} ${user.lastname ? user.lastname : ''}`
	};

	const passportDataIsFull = (passport) => {
		if(isEmpty(passport))
			return false
		else if(isEmpty(passport.seria))
			return false
		else if(isEmpty(passport.number))
			return false
		else if(isEmpty(passport.who))
			return false
		else if(isEmpty(passport.department))
			return false
		else if(isEmpty(passport.when))
			return false
		else
			return true
	};

	const passportScansIsFull = (passport) => {
		if(isEmpty(passport))
			return false
		else if(passport.scans == null || passport.scans.length === 0)
			return false
		else
			return true
	};

	const isEmpty = (data) => {
		return data === undefined || data === null || data === 'null' || data === ''
	};

	const winnerPrizeStatus = (status) => {
		switch (status) {
			case 'none':
				return 'Не определено'
			case 'wait':
				return 'Ожидает отправки'
			case 'sent':
				return 'Отправлен'
			case 'cancelled':
				return 'Отменено'
			default:
				return 'Неизвестный статус'
		}
	};

	React.useEffect(() => {
		// configure scanner for QR
		const qrScanner = new Html5QrcodeScanner(
			'qr-scanner',
			{
				fps: 30,
				qrbox: { width: 150, height: 150 },
			},
			false
		);

		// configure scanner for Bill
		const billScanner = new Html5QrcodeScanner(
			'qr-scanner-bill',
			{
				fps: 30,
				qrbox: { width: 150, height: 150 },
			},
			false
		);

		SetQrScanner(qrScanner);
		SetBillScanner(billScanner);

		return () => {
			if (QrScanner) QrScanner.clear().catch(() => {});
			if (BillScanner) BillScanner.clear().catch(() => {});
		};
	}, []);

	React.useEffect(() => {
		// check if the participant token was cleared - do logout from system
		console.log('participantToken was changed')
		if (!participantToken) {
			logoutAccount();
		}
	}, [participantToken]);

	React.useEffect(() => {
		if (successLogin) {
			showSuccessMessage('Вы успешно вошли в ваш Личный кабинет!');

			// remove error value from href
			//console.log(searchParams)
			if (searchParams.has("error")) {
				searchParams.delete("error")
				setSearchParams(searchParams);
			}

			showCabinetView();
		}
	}, [successLogin]);

	React.useEffect(() => {
		if (successRegistration) {
			showSuccessMessage('Вы успешно прошли регистрацию. Проверьте вашу почту или телефон указанные при регистрации. Мы отправили вам пароль для входа в Личный кабинет!');

			showLoginView();
		}
	}, [successRegistration]);

	React.useEffect(() => {
		if (participant) {
			//showSuccessMessage('Информация об участнике успешно получена!');

			showParticipantView();

			setBalance(participant.totalScore ? participant.totalScore : 0)
		}
		else {
			// hide all active views
			SetParticipantView(false)
			SetPassportDataView(false)
			SetPassportScanView(false)
			SetRegisterBillView(false)
			SetRegisterQuestionView(false)
			SetQuizView(false)
		}
	}, [participant]);

	React.useEffect(() => {
		if (passportUploaded) {
			showSuccessMessage('Информация о паспорте успешно загружена!');

			showCabinetView()

			clearNotifications()
		}
	}, [passportUploaded]);

	React.useEffect(() => {
		if (passportScanUploaded) {
			showSuccessMessage('Фотография паспорта успешно загружена!');

			showCabinetView()

			clearNotifications()
		}
	}, [passportScanUploaded]);

	React.useEffect(() => {
		if (billUploaded) {
			showSuccessMessage('Покупка успешно зарегистрирована!');

			clearRegisterBillView()
			showCabinetView()

			clearNotifications()
		}
	}, [billUploaded]);

	React.useEffect(() => {
		if (questionUploaded) {
			showSuccessMessage('Ваш вопрос успешно зарегистрирован!');

			clearRegisterQuestionView()
			showCabinetView()

			clearNotifications()
		}
	}, [questionUploaded]);

	React.useEffect(() => {

		console.log('participantToken: ' + participantToken)

		const currentDate = new Date().getTime();

		// parse promotion prizes
		const prizes = [];

		for (const prize of promotion.prizes)
			// if (
			// 	currentDate >= new Date(prize.drawingPromoStartedAt).getTime() &&
			// 	currentDate <= new Date(prize.drawingPromoEndedAt).getTime()
			// )
			prizes.push(prize.full_name ? prize.full_name : prize.name);

		SetPrizes(prizes);

		// parse promotion games
		SetPromotionGames(promotion.games ? promotion.games : []);

	}, [promotion]);

	React.useEffect(() => {

		if(participant) {
			// check codes history
			const historyCodes = [];
			if(participant.codes) {
				participant.codes.sort(function(a,b){
					return new Date(a.createdAt) - new Date(b.createdAt);
				});

				for (const code of participant.codes) {
					historyCodes.push(code);
				}
			}
			SetCodes(historyCodes);

			// check games history
			const historyGames = [];
			if(participant.games) {
				participant.games.sort(function(a,b){
					return new Date(a.createdAt) - new Date(b.createdAt);
				});

				for (const game of participant.games) {
					historyGames.push(game);
				}
			}
			SetGames(historyGames);

			// check prizes history
			const historyPrizes = [];
			if(participant.prizes) {
				participant.prizes.sort(function(a,b){
					return new Date(a.createdAt) - new Date(b.createdAt);
				});

				for (const prize of participant.prizes) {
					historyPrizes.push(prize);
				}
			}
			SetParticipantPrizes(historyPrizes);

			// check questions history
			const historyQuestions = [];
			if(participant.questions) {
				participant.questions.sort(function(a,b){
					return new Date(a.createdAt) - new Date(b.createdAt);
				});

				for (const question of participant.questions) {
					historyQuestions.push(question);
				}
			}
			SetQuestions(historyQuestions);
		}
		else {
			SetCodes([]);
			SetGames([]);
			SetParticipantPrizes([]);
			SetQuestions([]);
		}

	}, [participant]);

	return (
		<div className={'main-page'}>
			<section className='promo'>
				<div className='promo-text'>
					<h2>{promotion.texts.title}</h2>
					<p>{promotion.texts.description}</p>
				</div>
			</section>

			{/* <section className='how-to-participate'>
				<h2>Как участвовать</h2>
				<ol>
					<li>Сделайте покупку кириешек в нашем магазине.</li>
					<li>Сохраните чек или заказ.</li>
					<li>Зарегистрируйтесь на сайте.</li>
					<li>Участвуйте в розыгрыше призов!</li>
				</ol>
			</section> */}

			<section className='prizes'>
				<h2>Призы</h2>

				{Prizes.length ? (
					<ul>
						{Prizes.map((v, i) => (
							<li key={i}>
								<strong>{v}</strong>
							</li>
						))}
					</ul>
				) : (
					<span>В данный момент призы еще не определены</span>
				)}
			</section>


			{(!participantToken) && (
				<section className='addiitonal-info'>
					<h2>Добро пожаловать. Если вы уже регистрировались в данной акции - войдите в ваш Личный кабинет. Если вы впервые у нас - пройдите простую регистрацию.</h2>

					<div className={'addiitonal-info-components'}>
						<button
							className={'cta-button'}
							onClick={showRegisterView}
							type={'submit'}
						>
							Регистрация
						</button>
						<button
							className={'cta-button'}
							onClick={showLoginView}
							type={'submit'}
						>
							Войти
						</button>
					</div>
				</section>
			)}

			{(loginView && !participantToken) && (
				<section className='registration'>
					<h2>Вход в Личный Кабинет</h2>
					<p>Для входа необходимо ввести данные для входа:</p>

					<Formik
						initialValues={{
							email: '',
							phone: '',
							login: '',
							password: '',
						}}
						onSubmit={loginParticipant}
					>
						{({ setFieldValue, handleChange, handleSubmit, errors, touched, values }) => (
							<>
								{promotion.register_participants.email && !promotion.register_participants.phone && (
									<>
										<label htmlFor='email'>Email</label>
										<input
											className={errors.email && touched.email ? 'error' : ''}
											id={'email'}
											type={'email'}
											value={values.email}
											onChange={handleChange}
											required={true}
										/>
									</>
								)}

								{(promotion.register_participants.phone === true && !promotion.register_participants.email) && (
									<>
										<label htmlFor='phone'>Телефон (с кодом страны, пр. 7... для России)</label>
										<input
											className={errors.phone && touched.phone ? 'error' : ''}
											type={'tel'}
											id={'phone'}
											value={values.phone}
											onChange={handleChange}
											required={true}
										/>
									</>
								)}

								{(promotion.register_participants.phone === true && promotion.register_participants.email) && (
									<>
										<label htmlFor='login'>Email или Телефон (с кодом страны, пр. 7... для России)</label>
										<input
											className={errors.phone && touched.phone ? 'error' : ''}
											type={'text'}
											id={'login'}
											value={values.login}
											onChange={handleChange}
											required={true}
										/>
									</>
								)}

								<label htmlFor='password'>Пароль</label>
								<input
									className={errors.password && touched.password ? 'error' : ''}
									type={'text'}
									id={'password'}
									value={values.password}
									onChange={handleChange}
									required={true}
								/>

								<button
									className={'cta-button'}
									onClick={handleSubmit}
									type={'submit'}
								>
									Войти
								</button>
							</>
						)}
					</Formik>

				</section>
			)}

			{registerView && (
				<section
					className='registration'
					id='registration'
				>
					<h2>Регистрация</h2>

					{promotion.status === 'active' ? (
						<>
							<p>
								Зарегистрируйтесь, чтобы участвовать в розыгрыше призов. Введите ваши контактные данные.
							</p>
							<Formik
								initialValues={{
									name: '',
									lastname: '',
									surname: '',
									address: '',
									email: '',
									phone: '',
									birthday: new Date(),
									gender: 'man',
									...promotion.personal_data.additional_fields.reduce(
										(result, field, index) => ({
											...result,
											[`additional_fields_${index}`]: field.type === 'date' ? new Date() : field.type === 'check' ? false : '',
										}),
										{}
									),
								}}
								validationSchema={registerValidator(promotion.personal_data)}
								onSubmit={registerParticipant}
							>
								{({ setFieldValue, handleSubmit, errors, touched, values }) => (
									<>
										{promotion.personal_data.name && (
											<>
												<label htmlFor='name'>Имя</label>
												<input
													className={errors.name && touched.name ? 'error' : ''}
													type={'text'}
													id={'name'}
													value={values.name}
													onChange={(e) => setFieldValue('name', e.target.value)}
													required={true}
												/>
											</>
										)}

										{promotion.personal_data.lastname && (
											<>
												<label htmlFor='lastname'>Фамилия</label>
												<input
													className={errors.lastname && touched.lastname ? 'error' : ''}
													type={'text'}
													id={'lastname'}
													value={values.lastname}
													onChange={(e) => setFieldValue('lastname', e.target.value)}
													required={true}
												/>
											</>
										)}

										{promotion.personal_data.surname && (
											<>
												<label htmlFor='surname'>Отчество</label>
												<input
													className={errors.surname && touched.surname ? 'error' : ''}
													type={'text'}
													id={'surname'}
													value={values.surname}
													onChange={(e) => setFieldValue('surname', e.target.value)}
													required={true}
												/>
											</>
										)}

										{promotion.personal_data.address && (
											<>
												<label htmlFor='address'>Адрес</label>
												<input
													className={errors.address && touched.address ? 'error' : ''}
													type={'text'}
													id={'address'}
													value={values.address}
													onChange={(e) => setFieldValue('address', e.target.value)}
													required={true}
												/>
											</>
										)}

										{promotion.personal_data.email && (
											<>
												<label htmlFor='email'>Почта</label>
												<input
													className={errors.email && touched.email ? 'error' : ''}
													type={'email'}
													id={'email'}
													value={values.email}
													onChange={(e) => setFieldValue('email', e.target.value)}
													required={true}
												/>
											</>
										)}

										{promotion.personal_data.phone && (
											<>
												<label htmlFor='phone'>Телефон (с кодом страны, пр. 7... для России)</label>
												<input
													className={errors.phone && touched.phone ? 'error' : ''}
													type={'tel'}
													id={'phone'}
													value={values.phone}
													onChange={(e) => setFieldValue('phone', e.target.value)}
													required={true}
												/>
											</>
										)}

										{promotion.personal_data.birthday && (
											<div className={'w-100'}>
												<label htmlFor='birthday'>Дата рождения</label>
												<DatePicker
													id={'birthday'}
													maxDate={new Date()}
													selected={values.birthday}
													onChange={(date) => setFieldValue('birthday', new Date(date))}
													required={true}
													className={errors.birthday ? 'error' : ''}
													showYearDropdown
												/>
											</div>
										)}

										{promotion.personal_data.gender && (
											<div>
												<label htmlFor='gender'>Пол</label>
												<select
													className={errors.gender && touched.gender ? 'error' : 'select'}
													name="gender"
													value={values.gender}
													onChange={(e) => setFieldValue('gender', e.target.value)}
													required={true}
												>
													<option value="man">Мужской</option>
													<option value="woman">Женский</option>
												</select>
											</div>
										)}

										{promotion.personal_data.additional_fields.map((v, i) => (
											<div key={i}>
												<label htmlFor={`additional_fields_${i}`}>{v.name}</label>
												{v.type === 'date' ? (
													<DatePicker
														id={`additional_fields_${i}`}
														selected={values[`additional_fields_${i}`]}
														onChange={(date) => setFieldValue(`additional_fields_${i}`, new Date(date))}
														className={errors[`additional_fields_${i}`] ? 'error' : ''}
														required={true}
													/>
												) : v.type === 'check' ? (
													<input
														className={errors[`additional_fields_${i}`] && touched[`additional_fields_${i}`] ? 'error' : ''}
														type={'checkbox'}
														id={`additional_fields_${i}`}
														value={values[`additional_fields_${i}`]}
														onChange={(e) => setFieldValue(`additional_fields_${i}`, e.target.checked)}
														required={true}
													/>
												) : (
													<input
														className={errors[`additional_fields_${i}`] && touched[`additional_fields_${i}`] ? 'error' : ''}
														type={'text'}
														id={`additional_fields_${i}`}
														value={values[`additional_fields_${i}`]}
														onChange={(e) => setFieldValue(`additional_fields_${i}`, e.target.value)}
														required={true}
													/>
												)}
											</div>
										))}

										<button
											className={'cta-button'}
											onClick={handleSubmit}
											type={'submit'}
										>
											Зарегистрироваться
										</button>
									</>
								)}
							</Formik>
						</>
					) : promotion.status === 'new' ? (
						<p>
							Акция начнется <strong>{promotion.startedAt.split('T')[0].split('-').reverse().join('.')}</strong>
						</p>
					) : (
						<p>
							<strong>Акция завершилась</strong>
						</p>
					)}
				</section>
			)}

			{passportView && (
				<section
					className='registration'
					id='registration'
				>
					<h2>Регистрация паспортных данных</h2>

					{promotion.personal_data.passport_data && participantToken && promotion.status === 'active' ? (
						<>
							<p>Заполните информацию из вашего паспорта, а также сделайте фотографию вашего паспорта, чтобы участвовать в розыгрыше призов</p>
							<Formik
								initialValues={{
									scan: null,
									seria: '',
									number: '',
									who: '',
									department: '',
									when: '',
								}}
								onSubmit={uploadPassportData}
							>
								{({ setFieldValue, handleChange, handleSubmit, errors, touched, values }) => (
									<>
										{passportDataView && (
											<>
												<label htmlFor='seria'>Серия</label>
												<input
													className={errors.seria && touched.seria ? 'error' : ''}
													id={'seria'}
													type={'text'}
													value={values.seria}
													onChange={handleChange}
													required={true}
												/>

												<label htmlFor='number'>Номер</label>
												<input
													className={errors.number && touched.number ? 'error' : ''}
													type={'text'}
													id={'number'}
													value={values.number}
													onChange={(e) => setFieldValue('number', e.target.value)}
													required={true}
												/>

												<label htmlFor='who'>Кем выдан</label>
												<input
													className={errors.who && touched.who ? 'error' : ''}
													type={'text'}
													id={'who'}
													value={values.who}
													onChange={(e) => setFieldValue('who', e.target.value)}
													required={true}
												/>

												<label htmlFor='department'>Код подразделения</label>
												<input
													className={errors.department && touched.department ? 'error' : ''}
													type={'text'}
													id={'department'}
													value={values.department}
													onChange={(e) => setFieldValue('department', e.target.value)}
													required={true}
												/>


												<div className={'w-100'}>
													<label htmlFor='when'>Когда выдан</label>
													<DatePicker
														id={'when'}
														maxDate={new Date()}
														selected={values.when}
														onChange={(date) => setFieldValue('when', new Date(date))}
														required={true}
														className={errors.when ? 'error' : ''}
														showYearDropdown
													/>
												</div>
											</>
										)}

										{passportScanView && (
											<>
												<label htmlFor='scan'>Скан паспорта</label>
												<input
													id={'scan'}
													type={'file'}
													required={true}
													className={errors.scan && touched.scan ? 'error' : ''}
													onChange={(e) => setFieldValue('scan', e.target.files[0])}
												/>
											</>
										)}

										<button
											className={'cta-button'}
											onClick={handleSubmit}
											type={'submit'}
										>
											Загрузить информацию
										</button>
									</>
								)}
							</Formik>
						</>
					) : promotion.status === 'new' ? (
						<p>
							Акция начнется <strong>{promotion.startedAt.split('T')[0].split('-').reverse().join('.')}</strong>
						</p>
					) : (
						<p>
							<strong>Акция завершилась</strong>
						</p>
					)}
				</section>
			)}

			{(participantView && participant) && (
				<section
					className='registration'
					id='registration'
				>
					<h2>Личный кабинет</h2>

					<p><strong>Имя:</strong>{getFullName(participant.fk_user)}</p>
					<p><strong>Email:</strong>{participant.fk_user.email}</p>
					<p><strong>Телефон:</strong>{participant.fk_user.phone}</p>
					<p><strong>Кол-во баллов:</strong>{participant.totalScore}</p>
					<br/>

					{(promotion.status === 'active') ? (
						<>
							<button
								className={'cta-button'}
								onClick={showRegisterBillView}
								type={'submit'}
							>
								Зарегистрировать покупку
							</button>

							{PromotionGames.includes('de5fdeb3-e7a7-446d-ba93-9a9d0dd3a63d') && (
								<>
									<button
										className={'cta-button-space-top'}
										onClick={showQuizGameView}
										type={'submit'}
									>
										Играть в онлайн-викторину «Карусель рецептов»
									</button>
								</>
							)}
							<button
								className={'cta-button-space-top'}
								onClick={showRegisterQuestionView}
								type={'submit'}
							>
								Обратиться в поддержку
							</button>
						</>
					) : promotion.status === 'new' ? (
						<p>
							Акция начнется <strong>{promotion.startedAt.split('T')[0].split('-').reverse().join('.')}</strong>
						</p>
					) : (
						<p>
							<strong>Акция завершилась</strong>
						</p>
					)}

					{(
						<>
							<button
								className={'exit-button'}
								type={'button'}
								onClick={logoutAccount}
								//disabled={values.code}
							>
								Выйти из кабинета
							</button>
						</>
					)}

				</section>
			)}

			<div
				id={'qr-scanner'}
				style={ShowQRScaner ? { height: 240, width: 320 } : { display: 'none' }}
			/>

			<div
				id={'qr-scanner-bill'}
				style={ShowBillScaner ? { height: 240, width: 320 } : { display: 'none' }}
			/>

			{quizView && (
				<section
					className='registration'
					id='quiz'
				>
					<h2>Онлайн-викторина «Карусель рецептов»</h2>

					<Game
						balance={balance}
						promotion={promotion}
						participant={participant}
						token={participantToken ? participantToken : null}
						showError={showErrorMessage}
						showSuccess={showSuccessMessage}
						getParticipantData={getParticipantData}
					/>
				</section>
			)}

			{registerBillView && (
				<section
					className='registration'
					id='registration'
				>
					<h2>Регистрация покупки</h2>

					{promotion.status === 'active' ? (
						<>
							<Formik
								innerRef={FormikRef}
								initialValues={{
									qr: '',
									//codes: [searchParams.get('code')],
									code: '',
									file: null,
								}}
								onSubmit={registerBill}
							>
								{({ setFieldValue, handleSubmit, errors, touched, values }) => (
									<>
										{promotion && promotion.register_codes && (promotion.register_codes.qr_and_bill || promotion.register_codes.qr) && (
											<div>
												<p><strong>-</strong> Отсканируйте QR код товара</p>
												{!ShowQRScaner && (
													<div>
														{values && values.code ? (
															<p>
																QR код товара успешно сканирован!
															</p>
														):(
															<div/>
														)}
														<button
															className={(errors.code && !values.code ? 'error ' : values.code ? 'success ' : '') + 'open-scanner-button'}
															type={'button'}
															onClick={startScanQr}
															//disabled={values.code}
														>
															Сканировать QR
														</button>
													</div>
												)}
											</div>
										)}


										{promotion && promotion.register_codes && (promotion.register_codes.promocode_and_bill || promotion.register_codes.promocode) && (
											<div>
												<label htmlFor='name'>Промо код</label>
												<input
													className={errors.code && touched.code ? 'error' : ''}
													type={'text'}
													id={'code'}
													value={values.codecode}
													onChange={(e) => setFieldValue('code', e.target.value)}
													required={true}
												/>
											</div>
										)}

										{promotion && promotion.register_codes && (promotion.register_codes.qr_and_bill || promotion.register_codes.promocode_and_bill || promotion.register_codes.bill) && (
											<div>
												<p><strong>-</strong> Отсканируйте QR код чека, в котором указан данный товар</p>
												{!ShowBillScaner && (
													<div>
														{values && values.qr ? (
															<p>
																Чек был успешно сканирован!
															</p>
														):(
															<div/>
														)}
														<button
															className={(errors.qr && !values.qr ? 'error ' : values.qr ? 'success ' : '') + 'open-scanner-button'}
															type={'button'}
															onClick={startScanBill}
															//disabled={values.qr}
														>
															Сканировать Чек
														</button>
													</div>
												)}
												<p>
													или
												</p>
												{(
													<>
														<label htmlFor='file'>Сфотографировать Чек</label>
														<input
															id={'file'}
															type={'file'}
															required={true}
															className={errors.file && touched.file ? 'error' : ''}
															onChange={(e) => setFieldValue('file', e.target.files[0])}
														/>
													</>
												)}
											</div>
										)}

										<br/>
										<button
											className={'cta-button'}
											onClick={handleSubmit}
											type={'submit'}
										>
											Зарегистрировать покупку
										</button>
									</>
								)}
							</Formik>
						</>
					) : promotion.status === 'new' ? (
						<p>
							Акция начнется <strong>{promotion.startedAt.split('T')[0].split('-').reverse().join('.')}</strong>
						</p>
					) : (
						<p>
							<strong>Акция завершилась</strong>
						</p>
					)}
				</section>
			)}

			{(registerQuestionView) && (
				<section className='registration'>
					<h2>Регистрация обращения в поддержку</h2>

					<Formik
						innerRef={FormikQuestion}
						initialValues={{
							question: '',
						}}
						onSubmit={registerQuestion}
					>
						{({ setFieldValue, handleChange, handleSubmit, errors, touched, values }) => (
							<>
								<label htmlFor='email'>Ваш вопрос</label>
								<textarea
									className={errors.question && touched.question ? 'error' : 'w-100'}
									id={'question'}
									type={'text'}
									rows={"5"}
									value={values.question}
									onChange={handleChange}
									required={true}
								/>

								<button
									className={'cta-button-space-top'}
									onClick={handleSubmit}
									type={'submit'}
								>
									Отправить
								</button>
							</>
						)}
					</Formik>

				</section>
			)}

			{participantView && (
				<section className='prizes'>
					<h2>История регистраций покупок</h2>

					{Codes.length ? (
						<ul>
							{Codes.map((v, i) => (
								<li key={i}>
									<p>----------</p>
									<p>{`Дата: ${parseFullTime(v.createdAt)}`}</p>
									<p>{`Код: ${v.codeValue ? v.codeValue : '-'}`}</p>
									<p>{`Статус чека: ${billStatus(v.qrStatusCode)}`}</p>
									<p>{`Чек подтвержден: ${v.billValid ? 'Подтвержден' : 'Не подтвержден'}`}</p>
									<p>{`Кол-во балов: ${v.billScore ? v.billScore : 0}`}</p>
								</li>
							))}
						</ul>
					) : (
						<span>В данный момент у вас нет записей</span>
					)}
				</section>
			)}

			{participantView && (
				<section className='prizes'>
					<h2>История участия в играх</h2>

					{Games.length ? (
						<ul>
							{Games.map((v, i) => (
								<li key={i}>
									<p>----------</p>
									<p>{`Дата: ${parseFullTime(v.createdAt)}`}</p>
									<p>{`Игра: ${v.fk_game ? v.fk_game.name : '-'}`}</p>
									<p>{`Кол-во балов: ${v.score ? v.score : 0}`}</p>
								</li>
							))}
						</ul>
					) : (
						<span>В данный момент у вас нет записей</span>
					)}
				</section>
			)}

			{participantView && (
				<section className='prizes'>
					<h2>История выигрышей в розыгрышах</h2>

					{ParticipantPrizes.length ? (
						<ul>
							{ParticipantPrizes.map((v, i) => (
								<li key={i}>
									<p>----------</p>
									<p>{`Дата: ${parseFullTime(v.createdAt)}`}</p>
									<p>{`Категория: ${v.prize ? v.prize.category : '-'}`}</p>
									<p>{`Приз: ${v.prize ? v.prize.name : '-'}`}</p>
									<p>{`Статус: ${winnerPrizeStatus(v.status)}`}</p>
								</li>
							))}
						</ul>
					) : (
						<span>В данный момент у вас нет записей</span>
					)}
				</section>
			)}

			{participantView && (
				<section className='prizes'>
					<h2>История обращений в поддержку</h2>

					{Questions.length ? (
						<ul>
							{Questions.map((v, i) => (
								<li key={i}>
									<p>----------</p>
									<p>{`Дата: ${parseFullTime(v.createdAt)}`}</p>
									<p>{`Вопрос: ${v.question}`}</p>
									<p>{`Ответ: ${v.response ? v.response : (promotion && promotion.communications && promotion.communications.callCenter) ? promotion.communications.callCenterText : 'Нет ответа'}`}</p>
								</li>
							))}
						</ul>
					) : (
						<span>В данный момент у вас нет обращений</span>
					)}
				</section>
			)}

			{(promotion.personal_data.user_agreement ||
				promotion.personal_data.promotion_agreement ||
				promotion.personal_data.newsletters_agreement ||
				promotion.personal_data.textAboutPersonalData) && (
				<section className='addiitonal-info'>
					<h2>Дополнительная информация</h2>

					<div className={'addiitonal-info-components'}>
						{promotion.personal_data.user_agreement && (
							<a
								target={'_blank'}
								href={promotion.personal_data.user_agreement}
							>
								Согласие с польз. соглашением
							</a>
						)}
						{promotion.personal_data.promotion_agreement && (
							<a
								target={'_blank'}
								href={promotion.personal_data.promotion_agreement}
							>
								Согласие с правилами акции
							</a>
						)}
						{promotion.personal_data.newsletters_agreement && (
							<a
								target={'_blank'}
								href={promotion.personal_data.newsletters_agreement}
							>
								Согласие на получение рассылок
							</a>
						)}
						{promotion.personal_data.textAboutPersonalData && <strong>{promotion.personal_data.textAboutPersonalData}</strong>}
					</div>
				</section>
			)}

		</div>
	);
};

