import { takeEvery } from 'redux-saga/effects';
import { bindAsyncActions } from '../../../utils/store/helpers';

import ParticipantsApi from '../../../services/api/participants';
import {
	loginParticipantAction,
	loginParticipantActionAsync,
	registerParticipantAction,
	registerParticipantActionAsync,
	getParticipantDataAction,
	getParticipantDataActionAsync,
	registerParticipantPassportAction,
	registerParticipantPassportActionAsync,
	registerParticipantScanPassportAction,
	registerParticipantScanPassportActionAsync,
	registerParticipantBillAction,
	registerParticipantBillActionAsync,
	registerParticipantQuestionAction,
	registerParticipantQuestionActionAsync,
	changeParticipantsField,
	changeParticipantsFieldAsync,
	clearParticipantsError,
	clearParticipantsErrorAsync,
} from '../../actions/participants';

const plugeWorker = () => {
	return true;
};

const changeParticipantsFieldWorker = ({ name, value }) => {
	return { name, value };
};

export function* participantsSaga() {
	yield takeEvery(
		loginParticipantAction,
		bindAsyncActions(loginParticipantActionAsync)(ParticipantsApi.loginParticipantEndpoint)
	);
	yield takeEvery(
		registerParticipantAction,
		bindAsyncActions(registerParticipantActionAsync)(ParticipantsApi.registerParticipantEndpoint)
	);
	yield takeEvery(
		getParticipantDataAction,
		bindAsyncActions(getParticipantDataActionAsync)(ParticipantsApi.getParticipantDataEndpoint)
	);
	yield takeEvery(
		registerParticipantPassportAction,
		bindAsyncActions(registerParticipantPassportActionAsync)(ParticipantsApi.registerParticipantPassportEndpoint)
	);
	yield takeEvery(
		registerParticipantScanPassportAction,
		bindAsyncActions(registerParticipantScanPassportActionAsync)(ParticipantsApi.registerParticipantScanPassportEndpoint)
	);
	yield takeEvery(
		registerParticipantBillAction,
		bindAsyncActions(registerParticipantBillActionAsync)(ParticipantsApi.registerParticipantBillEndpoint)
	);
	yield takeEvery(
		registerParticipantQuestionAction,
		bindAsyncActions(registerParticipantQuestionActionAsync)(ParticipantsApi.registerParticipantQuestionEndpoint)
	);
	yield takeEvery(changeParticipantsField, bindAsyncActions(changeParticipantsFieldAsync)(changeParticipantsFieldWorker));
	yield takeEvery(clearParticipantsError, bindAsyncActions(clearParticipantsErrorAsync)(plugeWorker));
}
