import { all, take } from 'redux-saga/effects';
import { REHYDRATE } from 'redux-persist';

import { participantsSaga } from './participants';
import { promotionsSaga } from './promotions';

export default function* () {
	yield take(REHYDRATE);
	yield all([participantsSaga(), promotionsSaga()]);
}
