import { createActionFactory } from '../../../utils/store/helpers';

const factory = createActionFactory('PROMOTIONS');

export const getPromotionInfoAction = factory.create('GET_PROMOTION_INFO');
export const getPromotionInfoActionAsync = factory.createAsync('GET_PROMOTION_INFO_ASYNC');

export const changePromotionsField = factory.create('CHANGE_PROMOTIONS_FIELD');
export const changePromotionsFieldAsync = factory.createAsync('CHANGE_PROMOTIONS_FIELD_ASYNC');

export const clearPromotionsError = factory.create('CLEAR_PROMOTIONS_ERROR');
export const clearPromotionsErrorAsync = factory.createAsync('CLEAR_PROMOTIONS_ERROR_ASYNC');
