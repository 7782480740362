import { handleActions } from 'redux-actions';

import { getPromotionInfoActionAsync, changePromotionsFieldAsync, clearPromotionsErrorAsync } from '../../actions/promotions';

const initialState = {
	state: {},
	success: null,
	error: null,
};

export default handleActions(
	{
		[getPromotionInfoActionAsync.success]: (s, { payload: { data: requestData } } = {}) => ({
			...s,
			state: requestData.success ? requestData.promotion : {},
			success: requestData.success,
			error: requestData.success
				? null
				: requestData.message
				? requestData.message
				: 'Что-то пошло не так. Повторите попытку позже',
		}),
		[changePromotionsFieldAsync.success]: (s, { payload: { name, value } } = {}) => ({ ...s, [name]: value }),
		[clearPromotionsErrorAsync.success]: (s, a) => ({ ...s, error: null }),

		[getPromotionInfoActionAsync.failed]: (s, a) => ({
			...s,
			state: {},
			success: false,
			error: 'Что-то пошло не так. Повторите попытку позже',
		}),
	},
	initialState
);
