import { combineReducers } from 'redux';

import participantsReducer from './participants';
import promotionsReducer from './promotions';

export default () =>
	combineReducers({
		participants: participantsReducer,
		promotions: promotionsReducer,
	});
