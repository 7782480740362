import { handleActions } from 'redux-actions';

import {
	loginParticipantActionAsync,
	registerParticipantActionAsync,
	registerParticipantPassportActionAsync,
	registerParticipantScanPassportActionAsync,
	changeParticipantsFieldAsync,
	clearParticipantsErrorAsync,
	getParticipantDataActionAsync,
	registerParticipantBillActionAsync,
	registerParticipantQuestionActionAsync,
} from '../../actions/participants';

const initialState = {
	participantId: null,
	participantToken: null,
	participant: null,
	passportUploaded: null,
	passportScanUploaded: null,
	error: null,
	successRegistration: null,
	successLogin: null,
	billUploaded: null,
	questionUploaded: null,
};

export default handleActions(
	{
		[loginParticipantActionAsync.success]: (s, { payload: { data: requestData } } = {}) => ({
			...s,
			participantId: requestData.success ? requestData.participant : null,
			participantToken: requestData.success ? requestData.token : null,
			successLogin: requestData.success ? true : null,
			error: requestData.success
				? null
				: requestData.message
					? requestData.message
					: 'Что-то пошло не так. Повторите попытку позже',
		}),
		[registerParticipantActionAsync.success]: (s, { payload: { data: requestData } } = {}) => ({
			...s,
			successRegistration: requestData.success ? true : null,
			error: requestData.success
				? null
				: requestData.message
				? requestData.message
				: 'Что-то пошло не так. Повторите попытку позже',
		}),
		[getParticipantDataActionAsync.success]: (s, { payload: { data: requestData } } = {}) => ({
			...s,
			participant: requestData.success ? requestData.participant : null,
			error: requestData.success
				? null
				: requestData.message
					? requestData.message
					: 'Что-то пошло не так. Повторите попытку позже',
		}),
		[registerParticipantPassportActionAsync.success]: (s, { payload: { data: requestData } } = {}) => ({
			...s,
			passportUploaded: requestData.success,
			error: requestData.success
				? null
				: requestData.message
				? requestData.message
				: 'Что-то пошло не так. Повторите попытку позже',
		}),
		[registerParticipantScanPassportActionAsync.success]: (s, { payload: { data: requestData } } = {}) => ({
			...s,
			passportScanUploaded: requestData.success,
			error: requestData.success
				? null
				: requestData.message
					? requestData.message
					: 'Что-то пошло не так. Повторите попытку позже',
		}),
		[registerParticipantBillActionAsync.success]: (s, { payload: { data: requestData } } = {}) => ({
			...s,
			billUploaded: requestData.success,
			error: requestData.success
				? null
				: requestData.message
					? requestData.message
					: 'Что-то пошло не так. Повторите попытку позже',
		}),
		[registerParticipantQuestionActionAsync.success]: (s, { payload: { data: requestData } } = {}) => ({
			...s,
			questionUploaded: requestData.success,
			error: requestData.success
				? null
				: requestData.message
					? requestData.message
					: 'Что-то пошло не так. Повторите попытку позже',
		}),
		[changeParticipantsFieldAsync.success]: (s, { payload: { name, value } } = {}) => ({ ...s, [name]: value }),
		[clearParticipantsErrorAsync.success]: (s, a) => ({ ...s, error: null }),

		[loginParticipantActionAsync.failed]: (s, a) => ({
			...s,
			successLogin: null,
			participantId: null,
			participantToken: null,
			error: 'Что-то пошло не так. Повторите попытку позже',
		}),
		[getParticipantDataActionAsync.failed]: (s, a) => ({
			...s,
			participant: null,
			error: 'Что-то пошло не так. Повторите попытку позже',
		}),
		[registerParticipantActionAsync.failed]: (s, a) => ({
			...s,
			successRegistration: null,
			participantId: null,
			participantToken: null,
			error: 'Что-то пошло не так. Повторите попытку позже',
		}),
		[registerParticipantPassportActionAsync.failed]: (s, a) => ({
			...s,
			passportUploaded: null,
			error: 'Что-то пошло не так. Повторите попытку позже',
		}),
		[registerParticipantScanPassportActionAsync.failed]: (s, a) => ({
			...s,
			passportScanUploaded: null,
			error: 'Что-то пошло не так. Повторите попытку позже',
		}),
		[registerParticipantBillActionAsync.failed]: (s, a) => ({
			...s,
			billUploaded: null,
			error: 'Что-то пошло не так. Повторите попытку позже',
		}),
		[registerParticipantQuestionActionAsync.failed]: (s, a) => ({
			...s,
			questionUploaded: null,
			error: 'Что-то пошло не так. Повторите попытку позже',
		}),
	},
	initialState
);
