import { takeEvery } from 'redux-saga/effects';
import { bindAsyncActions } from '../../../utils/store/helpers';

import PromotionsApi from '../../../services/api/promotions';
import {
	getPromotionInfoAction,
	getPromotionInfoActionAsync,
	changePromotionsField,
	changePromotionsFieldAsync,
	clearPromotionsError,
	clearPromotionsErrorAsync,
} from '../../actions/promotions';

const plugeWorker = () => {
	return true;
};

const changePromotionsFieldWorker = ({ name, value }) => {
	return { name, value };
};

export function* promotionsSaga() {
	yield takeEvery(getPromotionInfoAction, bindAsyncActions(getPromotionInfoActionAsync)(PromotionsApi.getPromotionInfoEndpoint));
	yield takeEvery(changePromotionsField, bindAsyncActions(changePromotionsFieldAsync)(changePromotionsFieldWorker));
	yield takeEvery(clearPromotionsError, bindAsyncActions(clearPromotionsErrorAsync)(plugeWorker));
}
