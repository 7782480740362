import { Helmet } from 'react-helmet';
import React from 'react';

import InvalidPromotionContainer from '../../containers/InvalidPromotion';
import Footer from '../../components/Footer';

export default () => {
	return (
		<>
			<Helmet>
				<title>Акция не найдена</title>
			</Helmet>

			<div className={'invalid-promotion-wrapper'}>
				<InvalidPromotionContainer />

				<Footer />
			</div>
		</>
	);
};
