import React, { useState, useEffect } from 'react';
import '../../assets/styles/style.sass';
import axios from 'axios';

export const ModalReceipt = ({ isOpen, recipe, showError, closeAction, gameLoseAction, gameWinAction }) => {
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [timeLeft, setTimeLeft] = useState(180);

  useEffect(() => {
    let timer;

    if (isOpen) {
      setTimeLeft(180)
      setSelectedIngredients([]);
      setShowSuccess(false);
      timer = setInterval(() => {
        if(timeLeft < 0)
          clearInterval(timer);
        else
          setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isOpen]);

  useEffect(() => {
    if (timeLeft === 0) {
      gameLoseAction();
    }
  }, [timeLeft]);

  const handleIngredientClick = (ingredient) => {
    const isSelected = selectedIngredients.includes(ingredient);

    if (isSelected) {
      setSelectedIngredients(selectedIngredients.filter((item) => item !== ingredient));
    } else {
      setSelectedIngredients([...selectedIngredients, ingredient]);
    }
  };
  const handleSubmit = () => {
    if (selectedIngredients.length === 0) {
      showError('Необходимо выбрать ингредиенты');
    } else {
      const isCorrect = JSON.stringify(selectedIngredients.sort()) === JSON.stringify(recipe.trueIng.sort());

      if (isCorrect) {
        gameWinAction()
        setShowSuccess(true);
      } else {
        gameLoseAction()
      }

      setTimeLeft(-1)
    }
  };
  

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const openRecipe = () => {
    window.open(recipe.link, '_blank', 'noopener,noreferrer');
  };

  const closeView = () => {
    if(showSuccess) {
      closeAction()
    }
    else {
      gameLoseAction()
    }
  };

  return (
    <div className={`modal ${isOpen ? 'open' : ''}`}>
      <div className="modal-content">
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <div onClick={closeView} style={{fontWeight: 'bold', cursor: 'pointer'}}>X</div>
        <p>Осталось: {showSuccess ? 0 : formatTime(timeLeft)}</p>
        
        </div>
        <img src={recipe.image} alt={recipe.name} />
        <h2>{recipe.name}</h2>
        {showSuccess ? (
          <div>
            <p className="success-message">Вы угадали!</p>
            <button onClick={openRecipe} className={'game-button'}>Показать рецепт</button>
          </div>
        ) : (
          <>
            <ul className={'ul'}>
              {recipe.ingredients.map((ingredient, index) => (
                <li
                  key={index}
                  className={selectedIngredients.includes(ingredient) ? 'li-selected' : 'li'}
                  onClick={() => handleIngredientClick(ingredient)}
                >
                  {ingredient}
                </li>
              ))}
            </ul>

            <button className={'game-button'} onClick={handleSubmit}>Отправить ответ</button>
          </>
        )}

      </div>
    </div>
  );
};
