import * as yup from 'yup';

export default (requiries) => {
	const phoneNumber = (phone) => /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\\./0-9]*$/g.test(phone)

	yup.object().shape({
		name: yup.string().required(requiries.name),
		lastname: yup.string().required(requiries.lastname),
		surname: yup.string().required(requiries.surname),
		address: yup.string().required(requiries.address),
		email: yup.string().email().required(requiries.email),
		phone: yup
			.string()
			//.matches(/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/, '')
			//.matches(/[^0-9+]/g, '')
			.test(phoneNumber)
			.required(requiries.phone),
		birthday: yup.date().required(requiries.birthday),
		gender: yup.date().required(requiries.gender),
		//codes: yup.array(yup.string()).min(1).required(true),
		//qr: yup.string().required(true),
		...requiries.additional_fields.reduce(
			(result, field, index) => ({
				...result,
				[`additional_fields_${index}`]:
					field.type === 'date'
						? yup.date().required()
						: field.type === 'check'
							? yup.boolean().required()
							: yup.string().required(),
			}),
			{}
		),
	});
}
