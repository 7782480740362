import { api_url, cryptData, cryptoKey, tryToEncryptObject } from '../../common/config';
import React, { useState, useEffect } from 'react';
import '../../assets/styles/style.sass';
import {ModalReceipt} from "./ModalReceipt"
import { Wheel } from 'react-custom-roulette'
import axios from "axios";


const data = [
    { option: '0', style: { backgroundColor: 'red' }},
    { option: '1', style: { backgroundColor: 'white' } },
    { option: '2',style: { backgroundColor: 'red' }  },
    { option: '3',style: { backgroundColor: 'white' }  },
    { option: '4',style: { backgroundColor: 'red' }  },
    { option: '5',style: { backgroundColor: 'white' }  },
  ]
function Game({balance, promotion, participant, token, showError, showSuccess, getParticipantData, gameData}) {
    const GAME_ID = gameData ? gameData.id : 'de5fdeb3-e7a7-446d-ba93-9a9d0dd3a63d'
    const minScoreToPlay = gameData ? gameData.minimalScoreToPlay : 5
    const maxPlaysPerDay = gameData ? gameData.count : 3
    const gameWinScore = gameData ? gameData.score : 5

    const fakeRecipeData = [{
      id: '1',
      name: 'Паста',
      image: 'https://pulse.imgsmail.ru/imgpreview?mb=pulse&key=pic1135385645347674314',
      ingredients: ['Паста', 'Помидоры', 'Морковь', 'Сыр', 'Огурец', 'Картофель'],
      trueIng: ['Паста', 'Помидоры', 'Сыр'],
      link: 'http://www.vesnianka.ru/articles/Lections/Italy/Pasta.pdf'
    }];

    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);
    
    const [gameCount, setGameCount] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const closeModal = () => {
      setIsModalOpen(false);
    };

    const gameLoseAction = () => {
        registerGameResultAction(false)
        closeModal();
    };

    const gameWinAction = () => {
        registerGameResultAction(true)
    };

    const registerGameResultAction = (win) => {

        axios.post(
            `${api_url}landing/game`,
            tryToEncryptObject({
                participant: participant ? participant.id : null,
                promotion: promotion ? promotion.id : null,
                game: GAME_ID,
                status: win ? 'win' : 'lose',
            }),
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        ).then((res) => {
            console.log(`${api_url}landing/game/win`)
            console.log(res)

            if(res.status === 200) {
                let response = res.data ? res.data : {}

                if(response.success) {
                    if (win)
                        showSuccess(`Поздравляем! Вы выиграли ${gameWinScore} баллов!`);
                    else
                        showError(`К сожалению, вы проиграли!`);
                }
                else
                    showError(response.message ? response.message : response.error ? response.error : 'К сожалению мы не смогли сохранить ваш ответ. Попробуйте еще раз')
            }
            else {
                showError(res.error ? res.error : 'К сожалению мы не смогли сохранить ваш ответ. Попробуйте еще раз')
            }

            getParticipantData()
        });
    };


    const handleSpinClick = () => {
        console.log('handleSpinClick')

        if(balance < minScoreToPlay) {
            showError(`Для участия в игре, вам необходимо иметь минимум ${minScoreToPlay} баллов.`)
        }
        else if(gameCount < 1) {
            showError(`Вы больше не можете играть. Максимальное кол-во для участия в игре: ${maxPlaysPerDay} раз.`)
        }
        else if(mustSpin) {
            showError(`Дождитесь окончания текущей игры.`)
        }
        else {
            const newPrizeNumber = Math.floor(Math.random() * data.length);
            setPrizeNumber(newPrizeNumber);
            setMustSpin(true);
        }

    }
    const handleSpinStopped = () => {
      setMustSpin(false);
      setIsModalOpen(true)
    }

    useEffect(() => {
        let count = 0

        if(participant && gameData && participant.games) {

            // calculate for today
            // let todayDate = new Date()
            // let gamesToday = 0
            //
            // // try to calculate how many games user did today
            // participant.games.map((game) => {
            //     let gameDate = new Date(game.createdAt)
            //
            //     if(
            //         gameDate &&
            //         gameDate.getFullYear() === todayDate.getFullYear() &&
            //         gameDate.getMonth() === todayDate.getMonth() &&
            //         gameDate.getDate() === todayDate.getDate()
            //     )
            //         gamesToday ++;
            // })
            //
            // count = maxPlaysPerDay - gamesToday


            // calculate for all period
            const alreadyPlays = participant.games.filter((game) => game.game === gameData.id).length
            count = maxPlaysPerDay - alreadyPlays
        }

        setGameCount(count)
    }, [participant]);

    return (
      <>
          <header>
            <div>
              <h4>Доступно игр: {(balance < minScoreToPlay) ? `Для участия в игре, вам необходимо иметь минимум ${minScoreToPlay} баллов.` : gameCount}</h4>
              {(gameCount === 0) ? <h4>Вы потратили все попытки участия в данной игре</h4>:''}
            </div>
          </header>
          <div className='root'>
            <Wheel
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNumber}
              data={data}
              onStopSpinning={handleSpinStopped}
            />
            <button className={'game-button'} onClick={handleSpinClick}>Раскрутить!</button>
          </div>
          <ModalReceipt
              isOpen={isModalOpen}
              recipe={fakeRecipeData[0]}
              showError={showError}
              closeAction={closeModal}
              gameLoseAction={gameLoseAction}
              gameWinAction={gameWinAction}
          />
      </>
    )
  }
  
  export default Game
  
