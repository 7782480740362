import { createActionFactory } from '../../../utils/store/helpers';

const factory = createActionFactory('PARTICIPANTS');

export const loginParticipantAction = factory.create('LOGIN_PARTICIPANT');
export const loginParticipantActionAsync = factory.createAsync('LOGIN_PARTICIPANT_ASYNC');

export const registerParticipantAction = factory.create('REGISTER_PARTICIPANT');
export const registerParticipantActionAsync = factory.createAsync('REGISTER_PARTICIPANT_ASYNC');

export const getParticipantDataAction = factory.create('GET_PARTICIPANT_DATA');
export const getParticipantDataActionAsync = factory.createAsync('GET_PARTICIPANT_DATA_ASYNC');

export const registerParticipantPassportAction = factory.create('REGISTER_PARTICIPANT_PASSPORT');
export const registerParticipantPassportActionAsync = factory.createAsync('REGISTER_PARTICIPANT_PASSPORT_ASYNC');
export const registerParticipantScanPassportAction = factory.create('REGISTER_PARTICIPANT_SCAN_PASSPORT');
export const registerParticipantScanPassportActionAsync = factory.createAsync('REGISTER_PARTICIPANT_SCAN_PASSPORT_ASYNC');

export const registerParticipantBillAction = factory.create('REGISTER_PARTICIPANT_BILL');
export const registerParticipantBillActionAsync = factory.createAsync('REGISTER_PARTICIPANT_BILL_ASYNC');

export const registerParticipantQuestionAction = factory.create('REGISTER_PARTICIPANT_QUESTION');
export const registerParticipantQuestionActionAsync = factory.createAsync('REGISTER_PARTICIPANT_QUESTION_ASYNC');

export const changeParticipantsField = factory.create('CHANGE_PARTICIPANTS_FIELD');
export const changeParticipantsFieldAsync = factory.createAsync('CHANGE_PARTICIPANTS_FIELD_ASYNC');

export const clearParticipantsError = factory.create('CLEAR_PARTICIPANTS_ERROR');
export const clearParticipantsErrorAsync = factory.createAsync('CLEAR_PARTICIPANTS_ERROR_ASYNC');
