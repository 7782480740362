import { useSearchParams } from 'react-router-dom';
import { Routes, Route } from 'react-router';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import React from 'react';

import HomePage from '../../pages/Home';
import InvalidPromotionPage from '../../pages/InvalidPromotion';

import RestrictAccess from '../RestrictAccess';

import { changeParticipantsField, clearParticipantsError } from './../../store/actions/participants';
import { getPromotionInfoAction, clearPromotionsError } from './../../store/actions/promotions';

export default connect(
	(s) => ({
		participantsError: s.participants.error,
		promotionsError: s.promotions.error,
	}),
	{
		getPromotionInfoAction,
		changeParticipantsField,

		clearParticipantsError,
		clearPromotionsError,
	}
)(
	({
		participantsError,
		promotionsError,
		getPromotionInfoAction,
		changeParticipantsField,
		clearParticipantsError,
		clearPromotionsError,
	}) => {
		const [searchParams] = useSearchParams();
		const [PromotionId, SetPromotionId] = React.useState(searchParams.get('promotion'));

		React.useEffect(() => {
			if (PromotionId) getPromotionInfoAction({ promotion: PromotionId });

			// changeParticipantsField({ name: 'successRegistration', value: null });
			// changeParticipantsField({ name: 'successLogin', value: null });
			// changeParticipantsField({ name: 'participantId', value: null });
			// changeParticipantsField({ name: 'participantToken', value: null });
			// changeParticipantsField({ name: 'participant', value: null });
			// changeParticipantsField({ name: 'passportUploaded', value: null });
			// changeParticipantsField({ name: 'passportScanUploaded', value: null });
			// changeParticipantsField({ name: 'billUploaded', value: null });
			// changeParticipantsField({ name: 'questionUploaded', value: null });

		}, [PromotionId]);

		React.useEffect(() => {
			if (participantsError) {
				toast(participantsError, {
					autoClose: 5000,
					closeOnClick: true,
					draggable: true,
					hideProgressBar: false,
					pauseOnHover: true,
					position: 'top-right',
					type: 'error',
					theme: 'colored',
				});

				clearParticipantsError();
			}
		}, [participantsError]);

		React.useEffect(() => {
			if (promotionsError) {
				toast(promotionsError, {
					autoClose: 5000,
					closeOnClick: true,
					draggable: true,
					hideProgressBar: false,
					pauseOnHover: true,
					position: 'top-right',
					type: 'error',
					theme: 'colored',
				});

				clearPromotionsError();
			}
		}, [promotionsError]);

		return (
			<Routes>
				<Route
					path={'/'}
					element={
						<RestrictAccess
							condition={!!PromotionId}
							result={true}
							redirect={'/invalid-promotion'}
						>
							<HomePage />
						</RestrictAccess>
					}
				/>

				<Route
					path={'/invalid-promotion'}
					element={<InvalidPromotionPage />}
				/>
			</Routes>
		);
	}
);
